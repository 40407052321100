<template>
  <div>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="values in errors_back" :key="values">
                    <li v-for="value in values" :key="value">{{value}}</li>
                </ul>
      </div>
    </b-alert>
    <b-card title="Values">
      <b-row>
        <!-- sorting -->
        <b-col md="3" sm="6" class="mt-1">
          <b-form-group
            label="Sort"
            label-cols-sm="2"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-25"
              >
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">
                  Asc
                </option>
                <option :value="true">
                  Desc
                </option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- filter -->
        <b-col md="3" class="mt-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="searchData">
                  Search
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="2" class="my-1">
          <b-button
            style="height: 2.142rem; line-height: 0.5;"
            v-if="authPermissions.includes('create')"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
            :to="{ path: '/Values/Add' }"
          >
            Add Value
          </b-button>
        </b-col>
        <!-- table -->
        <b-col cols="12">
          <b-table
            id="attributesTable"
            ref="values"
            striped
            hover
            responsive
            class="position-relative"
            :items="items"
            :fields="fields"
          >
            <template #cell(actions)="items">
              <!-- show button value -->
              <b-button
                class="mr-1"
                size="sm"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                type="submit"
                :to="{ path: '/Values/Show/' + items.item.id }"
              >
                <feather-icon
                  style="width: 18px; height: 18px;"
                  icon="EyeIcon"
                />
              </b-button>

              <!-- edit button value -->
              <b-button
                v-if="
                  authPermissions.includes('update') &&
                  items.item.attribute_name === 'color'
                "
                class="mr-1"
                size="sm"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                @click="updateValueModal(items.item)"
                v-b-modal.modal-prevent-closing
                variant="warning"
              >
                <feather-icon
                  icon="EditIcon"
                  style="width: 18px; height: 18px;"
                />
              </b-button>
              <!-- delete button value -->
              <b-button
                v-if="authPermissions.includes('delete')"
                class="mr-1"
                size="sm"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                type="submit"
                @click="deleteData(items.item.id)"
              >
                <feather-icon
                  icon="Trash2Icon"
                  style="width: 18px; height: 18px;"
                />
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <!-- add Price Model -->
      <b-modal
        id="modal-prevent-closing"
        cancel-variant="outline-secondary"
        @ok="updateValue(items.item)"
        ok-title="Update"
        cancel-title="Close"
        centered
        title="Update Value"
      >
        <validation-observer ref="updatedValueForm">
          <b-form @submit.prevent>
            <b-row>
              <!-- Color Hex Code -->
              <b-col md="6">
                <b-form-group label="Hex Code" label-for="hex_code">
                  <validation-provider
                    #default="{ errors }"
                    name="hex_code"
                    rules="required"
                  >
                    <b-form-input
                      v-model="updatedValue.hex_code"
                      type="color"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <!-- error handelr -->
        <b-alert
          v-height-fade.appear
          fade
          :show="showDismissibleAlert"
          @dismissed="showDismissibleAlert = false"
          variant="danger"
        >
          <h4 class="alert-heading">
            Alert
          </h4>
          <div class="alert-body">
            <ul v-for="(values , index) in errors_back" :key="index">
              <li v-for="(value , indexVal) in values" :key="indexVal">
                {{ value }}
              </li>
            </ul>
          </div>
        </b-alert>
      </b-modal>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
            @change="fetchData"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <div>
          <!-- start pagination -->
          <b-pagination
            @input="fetchData()"
            aria-controls="attributesTable"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>

import { required } from '@validations'
export default {
  data() {
    return {
      required,
      authPermissions: [],
      showDismissibleAlert: false,
      errors_back: [],
      errors_back2: [],
      perPage: 25,
      pageOptions: [25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'id',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      showDismissibleAlert: false,
      // table fields
      fields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'attribute_name',
          label: 'Attribute Name',
        },
        {
          key: 'hex_code',
          label: 'Color Hex Code',
        },
        {
          key: 'actions',
        },
      ],
      items: [],
      // update data
      updatedValue: {
        value_id: null,
        hex_code: null,
      },
    }
  },
  watch: {
    sortDesc: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    sortBy: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    filter: function () {
      if (this.filter == '' || this.filter == null) {
        this.fetchData()
      }
    },
  },
  created() {
    this.authPermissions = this.$store.state.authPermissions
    this.fetchData()
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }))
    },
    rows() {
      return this.totalRows
    },
  },
  
  methods: {
    updateValueModal(item) {
      this.errors_back2 = []
      this.showDismissibleAlert = false
      this.updatedValue.value_id = item.id
      this.updatedValue.hex_code =
        item.hex_code === null ? '#0000' : item.hex_code
    },
    handleOk(bvModalEvt, item) {
      
      bvModalEvt.preventDefault()
      this.updateValue()
    },
    updateValue() {
      this.$refs.updatedValueForm.validate().then((success) => {
        //  data{'hex_code' : this.hex_code}
        // fetch data of values
        axios.put('values/' + this.updatedValue.value_id, {
            hex_code: this.updatedValue.hex_code,
          })
          .then((result) => {
            
            // get the value from values array to update the hex code
            const value = this.items.filter(item =>{
              if(item.id == this.updatedValue.value_id) {

                item.hex_code = this.updatedValue.hex_code
              }
            })
            this.$nextTick(() => {
              this.$bvModal.hide('modal-prevent-closing')
            })
            this.$swal({
              position: 'center',
              icon: 'success',
              title: 'Your work has been saved',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .catch((err) => {
            
            this.errors_back2.length = 0
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back2.push({
                  error: err.response.data.data,
                })
              } else {
                this.errors_back2 = err.response.data.data
              }
              this.showDismissibleAlert = true
            } else {
              this.errors_back2 = []
              this.errors_back2.push({
                error: 'internal server error',
              })
              this.showDismissibleAlert = true
            }
          })
      })
    },
    searchData() {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    fetchData() {
      var url = null
      if (this.filter == null) {
        url =
          'values-pagi/' +
          this.perPage.toString() +
          '/sortBy-' +
          this.sortBy +
          '-' +
          this.sortDirection +
          '?page=' +
          this.currentPage
      } else {
        url =
          'values-pagi/' +
          this.perPage.toString() +
          '/sortBy-' +
          this.sortBy +
          '-' +
          this.sortDirection +
          '/' +
          this.filter +
          '?page=' +
          this.currentPage
      }

      // fetch data of values
      axios
        .get(url)
        .then((result) => {
          this.totalRows = result.data.data.meta.total
          this.items.length = 0
          
          this.items = result.data.data.data
          this.$refs.values.refresh()
          return this.items
        })
        .catch((err) => {
          //console.log(err)
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    // delete function of value
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete Valeu.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('values/' + id)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
